<script>
import CMSPage from './base/CMSPage'
export default {
  extends: CMSPage,
  watch: {
    $route (v, o) {
      if (v.path !== o.path) {
        this.init()
      }
    }
  }
}
</script>

<style>

</style>
